<template>
    <v-layout wrap>
        <v-flex xs12 v-if="isMobile()">
            <v-img :src="require('@/assets/images/logoRed.png')" aspect-ratio="1" max-width="250" max-height="130" class="pa-0 ma-0" alt="INCHARGE" />
        </v-flex>
        <v-flex xs12 sm6 v-else>
            <v-img :src="require('@/assets/images/logoRed.png')" aspect-ratio="1" max-width="250" max-height="130" class="pa-0 ma-0" alt="INCHARGE" />
        </v-flex>
        <v-flex xs12 sm6 v-if="!isMobile()">
            <v-layout wrap>
                <v-flex xs12><strong>Sollus</strong> <small>Indústria Eletrônica LTDA</small></v-flex>
                <v-flex xs12 class="py-0"><strong>CNPJ:</strong> 22.108.794/0001-44</v-flex>
                <v-flex xs12 class="pt-0"><strong>SAC:</strong> +55 (35) 3473-0824</v-flex>
            </v-layout>
        </v-flex>

        <v-flex xs12>
            <v-divider class="my-0 primary" style="opacity: 0.22" />
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>