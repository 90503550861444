
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
const footer = require("@/assets/images/footerReport.png");
const logo = require("@/assets/images/logoRed.png");

const docOptions = {
    orientation: "portrait",
    format: 'a4',
    unit: "mm", // 210 x 297
    autoPaging: 'text',
};

var doc = new jsPDF(docOptions);

const pageHeight = doc.internal.pageSize.height;
const pageWidth = doc.internal.pageSize.width;

function _printNormalAndBold(inputValue, startX, Y) {
    const arrayOfNormalAndBoldText = inputValue.split('**');
    arrayOfNormalAndBoldText.map((text, i) => {
        doc.setFont(undefined, 'bold');
        // every even item is a normal font weight item
        if (i % 2 === 0) {
            doc.setFont(undefined, 'normal');
        }
        doc.text(text, startX, Y);
        startX = startX + doc.getTextWidth(text);
    });
}

function printPage(condominium, printRange, printInterval, items, total, price) {
    doc.setFontSize(10);
    let img = new Image();
    img.src = logo;

    doc.addImage(img, "PNG", 2, 2, 57, 30, '', 'FAST');

    let Y = 10;
    doc.setFont(undefined, 'bold').text("RELATÓRIO GERENCIAL DE SERVIÇOS", 136, Y).setFont(undefined, 'normal');
    _printNormalAndBold("**Sollus** Indústria Eletrônica Ltda.", 60, Y);

    Y += 5;
    doc.text("CNPJ/MF: 22.108.794/0001-44", 60, Y);
    Y += 10;
    doc.text("SAC: +55 (35) 3473-0824", 60, Y);

    if (condominium) {
        const margimLeft = 5;
        if (condominium.name) {
            Y += 10;
            _printNormalAndBold(`**Nome:** ${condominium.name}`, margimLeft, Y);
        }
        if (condominium.cnpj) {
            Y += 5;
            _printNormalAndBold(`**CNPJ:** ${condominium.cnpj}`, margimLeft, Y);
        }
        if (condominium.address) {
            Y += 5;
            _printNormalAndBold(`**Endereço:** ${condominium.address}`, margimLeft, Y);
        }
        if (condominium.cep) {
            Y += 5;
            _printNormalAndBold(`**CEP:** ${condominium.cep}`, margimLeft, Y);
        }
        if (condominium.city) {
            Y += 5;
            _printNormalAndBold(`**Cidade / UF:** ${condominium.city}`, margimLeft, Y);
        }
    }

    doc.setFillColor(255, 88, 91);
    doc.roundedRect(145, 28, 59, 15, 1, 1, 'F')
    doc.roundedRect(145, 44, 59, 15, 1, 1, 'F')
    doc.roundedRect(85, 28, 59, 15, 1, 1, 'F')
    if (total && price > 0) {
        doc.roundedRect(85, 44, 59, 15, 1, 1, 'F');
    }

    doc.setTextColor(255, 255, 255);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(14);

    if (printRange) {
        doc.setFontSize(8);
        doc.text("Periodo", 147.5, 40);
        doc.setFontSize(14);
        doc.text(printRange, 147.5, 35.5);
    }

    if (printInterval) {
        doc.setFontSize(8);
        doc.text("Total de dias", 147.5, 56);
        doc.setFontSize(14);
        doc.text(printInterval, 147.5, 51.5);
    }

    if (total) {
        doc.setFontSize(8);
        doc.text("Energia consumida", 90, 40);
        doc.setFontSize(14);
        doc.text(`${total} kWh`, 90, 35.5);
    }

    if (total && price > 0) {
        doc.setFontSize(8);
        doc.text("Valor Total", 90, 56);
        doc.setFontSize(14);
        doc.text(`R$ ${Number.parseFloat(total * price).toFixed(2)}`, 90, 51.5);
    }

    doc.setFont(undefined, 'normal');
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(10);

    let cols = [
        { header: 'Bloco', dataKey: 'block' },
        { header: 'Apartamento', dataKey: 'apartment' },
        { header: 'Consumo', dataKey: 'consumption' },
    ];
    if (price > 0) {
        cols.push({ header: 'Preço', dataKey: 'price' })
    }

    autoTable(doc, {
        theme: 'grid',
        margin: { left: 5, right: 5 },
        headStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'bold', lineWidth: 0.5, lineColor: [255, 255, 255], halign: 'center' },
        bodyStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'normal', lineWidth: 0.5, lineColor: [255, 255, 255] },
        alternateRowStyles: { fillColor: [255, 88, 91], textColor: [255, 255, 255], fontStyle: 'normal', lineWidth: 0.5, lineColor: [255, 255, 255] },
        // columnStyles: { 0: { halign: 'center', fillColor: [255, 88, 91] } },
        startY: 70,
        columns: cols,
        body: items,
    })

    img.src = footer;
    doc.addImage(img, "PNG", 0, pageHeight - 125, pageWidth, 125, '', 'FAST');

    return doc;
}

export default {
    createCondominiumReport: (condominium, printRange, printInterval, items, total, price) => {
        doc = new jsPDF(docOptions);

        let itemsProcessed = JSON.parse(JSON.stringify(items))
        itemsProcessed.map(i => {
            i.consumption = `${i.consumption} kWh`;
            if (price)
                i.price = `R$ ${Number.parseFloat(Number.parseFloat(i.consumption) * Number.parseFloat(price)).toFixed(2)}`;
            return i;
        })

        doc = printPage(condominium, printRange, printInterval, itemsProcessed, total, price)

        return doc;
    },
};