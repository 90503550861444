<template>
    <div>
        <v-flex xs12 v-if="!list">
            <v-alert type="error"> Erro ao buscar os condominios. </v-alert>
        </v-flex>
        <v-flex xs12 v-else-if="list.length == 0">
            <v-alert type="error"> Nenhum condominio encontrado para gestão. </v-alert>
        </v-flex>
        <v-autocomplete
            v-else
            :value="value"
            @change="$emit('change', $event)"
            :items="list"
            :filter="customFilter"
            item-value="id"
            item-text="link"
            :label="$t('Condomínio')"
            deletable-chips
            :multiple="multiple"
            return-object
            small-chips
        >
            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-title v-html="data.item.link"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.name"></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            type: String,
            list: null,
        };
    },

    methods: {
        customFilter(item, queryText) {
            const searchText = queryText.toLowerCase();
            return (item.name && item.name.toLowerCase().indexOf(searchText) > -1) || (item.email && item.email.toLowerCase().indexOf(searchText) > -1);
        },
    },

    mounted() {
        this.$http
            .get("api/v2/condominium/syndic")
            .then((result) => {
                if (result) {
                    this.list = result.condominiuns;

                    if (this.list && this.list.length == 1) {
                        this.value = this.list[0]["id"];
                    }
                }
            })
            .catch(() => {
                this.list = null;
            });
    },
};
</script>